import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BaseRedirector from "./BaseRedirector";
import HomePage from "../Home/HomePage";
import CategoryPage from "../Category/CategoryPage";
import Login from "../Authentication/Login";
import Logout from "../Authentication/Logout";
import Register from "../Authentication/Register";
import ResetPassword from "../Authentication/ResetPassword/ResetPassword";
import ConfirmEmail from "../Authentication/ConfirmEmail";
import FullVideoList from "../FullVideoList/FullVideoList";
import GoPremium from "../GoPremium/GoPremium";
import Admin from "../Admin/Admin";
import UserPage from "../User/UserPage";
import Header from "../../Layout/Header";
import VideoPage from "../Video/VideoPage";
import EditProfile from "../User/EditProfile";
import DeleteProfile from "../User/DeleteProfile";
import PrivacyPolicy from "../Info/PrivacyPolicy";
import TermsOfService from "../Info/TermsOfService";
import About from "../Info/About";
import ContactUs from "../Info/ContactUs";
import NavigationContext from "../../Contexts/NavigationContext";
import BaseSEO from "./BaseSEO";
import Footer from "../../Layout/Footer/Footer";
import "video.js/dist/video-js.css";
import Median from "median-js-bridge";
import Cookies from "universal-cookie";
import ReloadSymbol from "../../Generic/ReloadSymbol";

function BasePage(props) {
  const baseContainerRef = useRef(null);
  const cookies = new Cookies();
  const [touchStartY, SetTouchStartY] = useState(null);
  const [touchMoveY, SetTouchMoveY] = useState(null);
  const [isAtTop, SetIsAtTop] = useState(true);
  const [shouldRefresh, SetShouldRefresh] = useState(false);
  const swipeThreshold = 200;

  function HandleScroll(e) {
    SetIsAtTop(e.target.scrollTop <= 0);
  }

  function HandleTouchStart(e) {
    if (!isAtTop) {
      SetTouchStartY(null);
      return;
    }

    SetTouchStartY(e.touches[0].clientY);
  }

  function HandleTouchEnd() {
    SetTouchMoveY(0);

    if (!shouldRefresh || !isAtTop || !touchStartY) {
      SetTouchStartY(null);
      SetShouldRefresh(false);
      return;
    }

    window.location.reload();
  }

  function HandleTouchMove(e) {
    if (!isAtTop || !touchStartY) {
      return;
    }

    const currentY = e.changedTouches[0].clientY;
    SetTouchMoveY(currentY);

    if (currentY - touchStartY >= swipeThreshold) {
      SetShouldRefresh(true);
    } else {
      SetShouldRefresh(false);
    }
  }

  useEffect(() => {
    if (Median.isNativeApp() && navigator.userAgent.includes("gonative")) {
      window.location.href = "https://staging.funker530.com";
      return;
    }

    if (Median.isNativeApp()) {
      Median.sidebar.setItems({ enabled: false });
    }

    let isBeta = cookies.get("beta");
    if (isBeta === false && window.location.href.includes("beta") === true) {
      window.location.href = "https://www.funker530.com";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <>
      <div className="base">
        <BrowserRouter>
          <NavigationContext baseContainerRef={baseContainerRef}>
            <BaseSEO />
            <Header />
            <div
              className="baseContainer"
              ref={baseContainerRef}
              onTouchStart={HandleTouchStart}
              onTouchEnd={HandleTouchEnd}
              onTouchMove={HandleTouchMove}
              onScroll={HandleScroll}
            >
              {isAtTop && (
                <ReloadSymbol
                  moveY={touchMoveY}
                  startY={touchStartY}
                  swipeThreshold={swipeThreshold}
                />
              )}
              <Routes>
                <Route path="/*" element={<HomePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/register" element={<Register />} />
                <Route path="/categorypage" element={<CategoryPage />} />
                <Route path="/video/:slug" element={<VideoPage />} />
                <Route
                  path="/search/*"
                  element={
                    <BaseRedirector redirectTo="/videos/all/all-time/date" />
                  }
                />
                <Route
                  path="/videos/:categories/:days/:sort/"
                  element={<FullVideoList type="Videos" />}
                />
                <Route path="/premium/*" element={<GoPremium />} />
                <Route
                  path="/admin"
                  element={<BaseRedirector redirectTo="/admin/videos" />}
                />
                <Route path="/admin/:type/:id" element={<Admin />} />
                <Route path="/admin/:type" element={<Admin />} />
                <Route path="/user/:username" element={<UserPage />} />
                <Route
                  path="/user/:username/delete"
                  element={<DeleteProfile />}
                />
                <Route path="editprofile" element={<EditProfile />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route
                  path="/confirmemail/resend"
                  element={<ConfirmEmail resend={true} />}
                />
                <Route path="/confirmemail" element={<ConfirmEmail />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/tos" element={<TermsOfService />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact-us" element={<ContactUs />} />
                {/* Routes to support Legacy Links */}
                <Route
                  path="/latest/*"
                  element={
                    <BaseRedirector redirectTo="/videos/all/all-time/date" />
                  }
                />
                <Route
                  path="/trending/*"
                  element={
                    <BaseRedirector redirectTo="/videos/all/this-week/views" />
                  }
                />
                <Route
                  path="/categories/:category"
                  element={
                    <BaseRedirector redirectTo="/videos/{category}/all-time/date" />
                  }
                />
                <Route
                  path="/categories/*"
                  element={
                    <BaseRedirector redirectTo="/videos/all/all-time/date" />
                  }
                />
                <Route
                  path="/subscriptions/*"
                  element={
                    <BaseRedirector redirectTo="/videos/all/all-time/date" />
                  }
                />
                <Route
                  path="/app/*"
                  element={
                    <BaseRedirector redirectTo="https://funker530.app.link/3M7FXz8jGhb" />
                  }
                />
                <Route
                  path="/store/*"
                  element={
                    <BaseRedirector redirectTo="https://sav-tac.com/collections/funker-530" />
                  }
                />
                <Route
                  path="/pro/*"
                  element={<BaseRedirector redirectTo="/premium" />}
                />
                <Route
                  path="/submit/*"
                  element={
                    <BaseRedirector redirectTo="https://docs.google.com/forms/d/e/1FAIpQLSdAunhve-ejliwO1rjCDE93r1baj6rU2xD3HoJghUdWRM5ufA/viewform" />
                  }
                />
                <Route
                  path="/accounts/reset/*"
                  element={
                    <BaseRedirector
                      redirectTo={
                        "https://staging.funker530.com" +
                        window.location.pathname +
                        window.location.search
                      }
                    />
                  }
                />
              </Routes>
            </div>
            <Footer />
          </NavigationContext>
        </BrowserRouter>
      </div>
    </>
  );
}

export default BasePage;
