import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

function SearchItems(props) {
  const [searchBuffer, SetSearchBuffer] = useState("");

  function ChangeSearchBuffer(e) {
    SetSearchBuffer(e.target.value);
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      props.ChangeSearch(searchBuffer);
    }, 500);
    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBuffer]);

  useEffect(() => {
    if (props.search !== searchBuffer) {
      SetSearchBuffer(props.search);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.search]);
  return (
    <div className="searchItems">
      <Icon icon="fluent:search-12-filled" className="searchIcon" />
      <input type="text" value={searchBuffer} onChange={ChangeSearchBuffer} />
      <div className="searchIncludeAll">
        <input
          type="checkbox"
          checked={props.includeAll}
          onChange={props.ToggleIncludeAll}
        />
        {props.includeAllText}
      </div>
      <div className="buttonsContainer">
        {props.buttons?.map((button, index) => (
          <button key={index} className="funkerButton" onClick={button.onClick}>
            {button.text}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SearchItems;
