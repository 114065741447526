import React from 'react';
import './Category.scss';
import FunkerPlayer from '../../Video/Player/FunkerPlayer';

const CategoryPage = () => {
  const videos = [
    { id: 1, bunnyId: 'ad65bef5-6bd2-4e25-85ed-0c258af9d22e', title: 'Video 1' },
    { id: 2, bunnyId: 'ad65bef5-6bd2-4e25-85ed-0c258af9d22e', title: 'Video 2' },
    { id: 3, bunnyId: 'ad65bef5-6bd2-4e25-85ed-0c258af9d22e', title: 'Video 3' },
    { id: 4, bunnyId: 'ad65bef5-6bd2-4e25-85ed-0c258af9d22e', title: 'Video 4' },
  ];

  return (
    <div className="ukraine-page-base">
      <div className="ukr-banner-container">
          <img src="/ukrainebanner.jpg" alt="Ukraine Banner" className="ukr-banner" />
      </div>
      <p className="category-quote">"Ukraine never had a tradition of genuine statehood." -Vladimir Putin</p>
      <div className="info-text-wrapper">
        <div className="info-text">
            <p>The Russo-Ukrainian War is an ongoing war between Russia and Ukraine, which began in February 2014. Following Ukraine's Revolution of Dignity, 
            Russia occupied and annexed Crimea from Ukraine and supported pro-Russian separatists fighting the Ukrainian military in the Donbas war. 
            The first eight years of conflict also included naval incidents, cyberwarfare, and heightened political tensions. In February 2022, Russia launched 
            a full-scale invasion of Ukraine and began occupying more of the country.</p>
            <p>On 24 February 2022, Putin announced a "special military operation" to "demilitarize and denazify" Ukraine, claiming Russia had no plans to occupy 
            the country. The Russian invasion that followed was internationally condemned; many countries imposed sanctions against Russia and increased existing 
            sanctions. In the face of fierce resistance, Russia abandoned an attempt to take Kyiv in early April. From August, Ukrainian forces began recapturing 
            territories in the north-east and south. In late September, Russia declared the annexation of four partially-occupied regions, which was 
            internationally condemned. Russia spent the winter conducting inconclusive offensives in the Donbas. In spring 2023, Russia dug into positions ahead of 
            another Ukrainian counteroffensive, which failed to gain significant ground. The war has resulted in a refugee crisis and tens of thousands of deaths.</p>
        </div>
      </div>
      <div className="category-grid-wrapper">
        <div className="category-grid">
          {videos.map((video) => (
          <div key={video.id} className="video-item">
            <div className="video-wrapper">
              <FunkerPlayer video={video} />
            </div>
          </div>
          ))}
        </div>
      </div>
      <div className="button-row">
        <button className="category-button">INVASION OF CRIMEA</button>
        <span className="arrow">></span>
        <button className="category-button">BATTLE OF KYIV</button>
        <span className="arrow">></span>
        <button className="category-button">KHARKIV OFFENSIVE</button>
        <span className="arrow">></span>
        <button className="category-button">KHERSON</button>
        <span className="arrow">></span>
        <button className="category-button">BATTLE OF BAKHMUT</button>
        <span className="arrow">></span>
        <button className="category-button">SUMMER OFFENSIVE</button>
      </div>
    </div>
  );
};

export default CategoryPage;
