import React, { useEffect, useRef, useState } from "react";
import "video.js/dist/video-js.css";
import "./FunkerVideoStyles.scss";
import "./VastPlugin/vast-player.css";
import * as SiteConstants from "../../Constants/SiteConstants";
import { renderToString } from "react-dom/server";
import { UseVideoContext } from "../../Contexts/VideoContext";
import LoadingIcon from "./LoadingIcon.js";
import axios from "axios";

function FunkerVideo(props) {
  const { volume, captions, UpdateVideoPlayerSettings, SendView } =
    UseVideoContext();
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const autoplayTimerRef = useRef(null);
  const unmuteButtonRef = useRef(null);
  const { options, onReady } = props;
  const prerollUrl = "https://funker530.engine.adglare.net/?627526255";
  const postrollUrl = "https://funker530.engine.adglare.net/?881168456";
  const [showControlsOnMobile, SetShowControlsOnMobile] = useState(true);
  const [posterUrl, SetPosterUrl] = useState({
    backgroundImage: "url('" + props.options.poster + "')",
  });
  const sentViewLastOn = useRef(null);
  const stalledSince = useRef(null);
  const lastReloadedAds = useRef(null);
  var videojs = null;
  var videojsContribAds = null;
  var VastPlugin = null;
  var qualityPlugin = null;

  async function ImportVideoJs() {
    const videojsImport = await import("video.js");
    const videojsContribAdsImport = await import("videojs-contrib-ads");
    const VastPluginImport = await import("./VastPlugin/vast-plugin");
    const videojsQualityImport = await import("./QualityPlugin/plugin");
    videojs = videojsImport.default;
    videojsContribAds = videojsContribAdsImport.default;
    VastPlugin = VastPluginImport.default;
    qualityPlugin = videojsQualityImport.default;

    if (!videojs.getPlugin("ads")) {
      videojs.registerPlugin("ads", videojsContribAds);
    }
    if (!videojs.getPlugin("vast")) {
      videojs.registerPlugin("vast", VastPlugin);
    }
    if (!videojs.getPlugin("quality")) {
      videojs.registerPlugin("quality", qualityPlugin);
    }
  }

  function PauseOnTouch(e) {
    if (
      !videoRef.current ||
      !playerRef.current ||
      e.target.tagName !== "VIDEO"
    ) {
      return;
    }

    if (videoRef.current.firstChild.classList.contains("vjs-user-active")) {
      playerRef.current.pause();
    }
  }

  function UnmutePlayer(e) {
    if (
      playerRef.current?.muted() &&
      unmuteButtonRef.current?.classList.contains("autoMuted")
    ) {
      playerRef.current.muted(false);
      unmuteButtonRef.current.classList.remove("autoMuted");
    }
  }

  function RegisterView() {
    if (playerRef.current && playerRef.current.currentTime() > 2) {
      SendView(props.postId);
      sentViewLastOn.current = props.postId;
    }
  }

  function ThumbnailMaker(item) {
    if (item.thumbnail_url) {
      return item.thumbnail_url;
    }

    if (item.thumbnail_url === null && item.thumbnail?.renditions == null) {
      return SiteConstants.DEFAULT_THUMBNAIL;
    }

    const thumbnail = item.thumbnail;
    let thumbnailUrl = SiteConstants.IMAGES_BASE_URL;
    let rendition;

    if (thumbnail.renditions?.length === 1) {
      thumbnailUrl += thumbnail.renditions[0].file;
      return thumbnailUrl;
    }

    if (window.matchMedia("(min-width: 1024px)").matches) {
      rendition = thumbnail.renditions.filter((r) => r.file.includes("300"))[0];
    } else {
      rendition = thumbnail.renditions.filter((r) => r.file.includes("165"))[0];
    }

    thumbnailUrl += rendition.file;

    return thumbnailUrl;
  }

  function RenderOverlay() {
    let recommendationsDiv =
      document.getElementsByClassName("recommendations")[0];
    if (!recommendationsDiv) {
      setTimeout(RenderOverlay, 500);
      return;
    }

    let slicedList = props.recommended;
    if (slicedList.length > 12) {
      slicedList = slicedList.slice(0, 12);
    }

    recommendationsDiv.innerHTML = renderToString(
      <>
        {props.recommended.map((item, index) => (
          <a
            href={"/video/" + item.slug}
            className="recommendationsItem"
            key={index}
          >
            <div className="recommendationsItemThumbnail">
              <img
                src={ThumbnailMaker(item)}
                alt={"Reccommended Thumbnail " + index}
                loading="lazy"
              />
            </div>
            <div className="recommendationsItemTitle">{item.title}</div>
          </a>
        ))}
      </>
    );
  }

  function SetSubtitlesClass() {
    if (playerRef.current?.cache_?.selectedLanguage?.enabled) {
      UpdateVideoPlayerSettings("captions", "showing");
      playerRef.current.el().classList.add("subtitlesEnabled");
      playerRef.current.el().classList.remove("subtitlesDisabled");
    } else {
      UpdateVideoPlayerSettings("captions", "disabled");
      playerRef.current.el().classList.remove("subtitlesEnabled");
      playerRef.current.el().classList.add("subtitlesDisabled");
    }
  }

  async function CheckForBufferStall() {
    let player = playerRef.current?.player_;
    let bufferedEnd = playerRef.current?.bufferedEnd();
    let currentTime = playerRef.current?.currentTime();
    let duration = playerRef.current?.duration();
    if (
      bufferedEnd &&
      currentTime &&
      duration &&
      bufferedEnd - currentTime < 1 &&
      bufferedEnd < duration - 2 &&
      currentTime > 0.03
    ) {
      if (stalledSince.current == null) {
        stalledSince.current = new Date();
        return;
      }
      if (new Date() - stalledSince.current < 1000) {
        return;
      }
      console.log("Buffer Stall Detected");
      await player.load();
      await player.currentTime(currentTime);

      await player.play();
    }
    stalledSince.current = null;
  }

  async function AddTextTrack(player) {
    let location = `https://vz-e00a99b1-cea.b-cdn.net/${props.videoId}/captions/en-auto.vtt`;
    let isValid = await axios.get(location).catch((error) => {
      return false;
    });
    if (!isValid) {
      return;
    }
    player.addRemoteTextTrack(
      {
        src: `https://vz-e00a99b1-cea.b-cdn.net/${props.videoId}/captions/en-auto.vtt`,
        srclang: "English (auto)",
        default: captions === "showing",
      },
      true
    );
  }

  async function InitializeVideoJs() {
    if (document.hidden) {
      setTimeout(InitializeVideoJs, 500);
      return;
    }
    await ImportVideoJs();
    // Make sure Video.js player is only initialized once
    let videoChanged = false;
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        const overlayElement = document.createElement("div");
        overlayElement.classList.add("recommendations");
        const unmuteButton = document.createElement("div");
        unmuteButton.classList.add("unmuteButtonContainer");
        unmuteButton.innerHTML = "<div class='unmuteButton'></div>";
        unmuteButton.addEventListener("click", UnmutePlayer);
        unmuteButtonRef.current = unmuteButton;
        const showControlsButton = document.createElement("div");
        showControlsButton.classList.add("showControlsButtonContainer");
        showControlsButton.innerHTML = "<div class='showControlsButton'></div>";
        showControlsButton.onclick = function (e) {
          SetShowControlsOnMobile((current) => {
            return !current;
          });
        };
        AddTextTrack(player);
        videoRef.current.addEventListener("touchstart", PauseOnTouch);
        player.on("texttrackchange", SetSubtitlesClass);
        player.on("volumechange", () => {
          UpdateVideoPlayerSettings("volume", playerRef.current.volume());
        });
        player.quality(options.quality);
        playerRef.current.el().appendChild(overlayElement);
        playerRef.current.el().appendChild(unmuteButton);
        const videoControlBar = playerRef.current
          .el()
          .querySelector(".vjs-control-bar");
        videoControlBar.appendChild(showControlsButton);
        onReady && onReady(player);

        ShowSvg(false);
      }));

      RenderOverlay();

      if (
        !props.subscriptionActive &&
        lastReloadedAds.current !== props.postId
      ) {
        lastReloadedAds.current = props.postId;
        player.vast({
          url: prerollUrl + "," + postrollUrl,
          unmuteFunction: UnmutePlayer,
          skip: 5,
          controlsEnabled: true,
          debug: true,
        });
      }

      SetSubtitlesClass();

      setInterval(CheckForBufferStall, 1000);
      videoChanged = true;

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      if (playerRef.current.src() !== options.sources[0].src) {
        playerRef.current.trigger("changedVideoSrc");
        playerRef.current.src(options.sources);
        videoChanged = true;
        if (
          !props.subscriptionActive &&
          lastReloadedAds.current !== props.postId
        ) {
          playerRef.current.trigger("reloadAds");
          lastReloadedAds.current = props.postId;
        }
      }
    }

    if (videoChanged) {
      if (autoplayTimerRef.current) {
        clearTimeout(autoplayTimerRef.current);
      }
      if (props.allwaysMuted) {
        playerRef.current.muted(true);
      }
      if (!props.autoplayDisabled) {
        autoplayTimerRef.current = setTimeout(() => {
          playerRef.current
            .play()
            .then(() => {})
            .catch(async (error) => {
              // Autoplay was prevented
              unmuteButtonRef.current.classList.add("autoMuted");
              playerRef.current.muted(true);
              await playerRef.current.play();
            });
        }, 500);
      }
    }
    UpdatePlayerSettings();
  }

  function UpdatePlayerSettings() {
    if (
      playerRef.current &&
      volume != null &&
      playerRef.current.volume() !== volume
    ) {
      playerRef.current.volume(volume);
      playerRef.current.trigger("volumechange");
    }
    let textTracks = playerRef.current?.textTracks();
    if (
      textTracks != null &&
      textTracks.length > 0 &&
      textTracks[0].mode !== captions
    ) {
      textTracks[0].mode = captions;
    }
  }

  function ShowSvg(spinner) {
    let svg = document.createElement("div");
    svg.classList.add("playButtonSvg");
    svg.innerHTML = renderToString(<LoadingIcon />);
    document.getElementById("loadingSpinnerContainer")?.remove();
    if (spinner) {
      svg.id = "loadingSpinnerContainer";
      document.getElementsByClassName("innerPlayer")[0].append(svg);
    } else {
      document.getElementsByClassName("vjs-big-play-button")[0].prepend(svg);
    }
  }

  useEffect(() => {
    SetPosterUrl({ backgroundImage: "url('" + props.options.poster + "')" });
    if (playerRef.current) {
      playerRef.current.poster(props.options.poster);
    }
  }, [props.options.poster]);

  useEffect(() => {
    if (!document.getElementById("loadingSpinner")) {
      ShowSvg(true);
    }
    InitializeVideoJs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current && !playerRef.current.isDisposed()) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    UpdatePlayerSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volume, captions, playerRef]);

  useEffect(() => {
    if (playerRef.current) {
      let playbackRateMenuButton = playerRef.current.controlBar.getChild(
        "PlaybackRateMenuButton"
      );
      let pictureInPictureToggle = playerRef.current.controlBar.getChild(
        "PictureInPictureToggle"
      );
      let subsCapsButton =
        playerRef.current.controlBar.getChild("SubsCapsButton");
      let qualityButton =
        playerRef.current.controlBar.getChild("QualityButton");
      if (showControlsOnMobile) {
        playbackRateMenuButton.show();
        pictureInPictureToggle.show();
        subsCapsButton.show();
        qualityButton.show();
      }
      if (!showControlsOnMobile) {
        playbackRateMenuButton.hide();
        pictureInPictureToggle.hide();
        subsCapsButton.hide();
        qualityButton.hide();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showControlsOnMobile]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (props.postId && sentViewLastOn.current !== props.postId) {
        RegisterView();
      }
    }, 2000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.postId]);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        // playerRef.current.dispose();
        // console.log("Player disposed", videojs.log.history());
        playerRef.current = null;
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    RenderOverlay();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef, props.recommended]);

  return (
    <div className="vjs-player" data-vjs-player style={posterUrl}>
      <div className="innerPlayer" ref={videoRef}></div>
    </div>
  );
}

export default FunkerVideo;
