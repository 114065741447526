import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import * as APIConstants from "../Constants/APIConstants";
import axios from "axios";
import Cookies from "universal-cookie";
import ReactGA from "react-ga4";

const CurrentContext = createContext();

function UserContext({ children }) {
  const cookies = new Cookies();
  const [user, SetUser] = useState(null);
  const [loggingIn, SetLoggingIn] = useState(true);
  const [sessionCookie, SetSessionCookie] = useState(
    cookies.get("sessionKey") ?? cookies.get("fmsst") ?? null
  );
  const [anonSession, SetAnonSession] = useState(null);
  const [loading, SetLoading] = useState(true);
  const [returnTo, SetReturnTo] = useState("/");
  const [pullToRefreshEnabled, SetPullToRefreshEnabled] = useState(true);

  function GenerateAnonSession() {
    let currentAnonSession =
      cookies.get("session") ?? crypto.randomUUID().replace(/-/g, "");
    cookies.set("session", currentAnonSession, {
      domain: "." + window.location.hostname.split(".").slice(-2).join("."),
      sameSite: "lax",
      path: "/",
      maxAge: 60 * 60 * 24 * 30,
    });
    SetAnonSession(currentAnonSession);
  }

  function SetReturnToPath(path, title = null) {
    axios
      .get("https://images.inthe.net/api/GetImage?u=" + (user?.id ?? "0"))
      .catch((error) => {});
    SetReturnTo(path);
    if (title) {
      let gaObject = {
        hitType: "pageview",
        page: path,
        title: title,
      };
      ReactGA.send(gaObject);
    }
  }

  async function Login(username, password) {
    SetLoading(true);
    SetLoggingIn(false);
    let formData = new FormData();
    formData.append("Email", username);
    formData.append("Password", password);

    let response = await axios({
      method: "post",
      url: APIConstants.API_URL + "Login?code=" + APIConstants.API_CODE,
      data: formData,
    }).catch((error) => {
      return error?.response;
    });

    let result = ProcessLogin(response);
    return result;
  }

  async function LoginBySession() {
    SetLoggingIn(true);
    let formData = new FormData();
    formData.append("sessionKey", sessionCookie);
    let response = "";
    response = await axios({
      method: "post",
      url: APIConstants.API_URL + "Login?code=" + APIConstants.API_CODE,
      data: formData,
    }).catch((error) => {
      SetLoggingIn(false);
      SetLoading(false);
      return error?.response;
    });
    let result = ProcessLogin(response);
    return result;
  }

  function ProcessLogin(response) {
    setTimeout(() => {
      SetLoggingIn(false);
      SetLoading(false);
    }, 400);
    if (
      response?.data != null &&
      response?.data !== "Login Failed" &&
      response?.status !== 400
    ) {
      SetUser(response.data);
      cookies.set("sessionKey", response.data.session.sessionKey, {
        domain: "." + window.location.hostname.split(".").slice(-2).join("."),
        sameSite: "lax",
        path: "/",
        maxAge: 60 * 60 * 24 * 7,
      });
      SetSessionCookie(response.data.session.sessionKey);
      return;
    }
    SetUser("none");
    return "error";
  }

  async function Register(username, email, password, ageOver18) {
    SetLoading(true);
    let formData = new FormData();
    formData.append("Username", username);
    formData.append("Email", email);
    formData.append("Password", password);
    formData.append("AgeOver18", ageOver18);
    formData.append("BaseUrl", window.location.origin);

    let response = await axios({
      method: "post",
      url: APIConstants.API_URL + "Register?code=" + APIConstants.API_CODE,
      data: formData,
    }).catch((error) => {
      return error?.response;
    });

    ProcessLogin(response);
    return response;
  }

  async function ConfirmEmail(username, token = "", resend = false) {
    SetLoading(true);
    let formData = new FormData();
    formData.append("Username", username);
    formData.append("Token", token);
    formData.append("Resend", resend);

    if (resend) {
      formData.append("BaseUrl", window.location.origin);
    }

    let response = await axios({
      method: "post",
      url: APIConstants.API_URL + "ConfirmEmail?code=" + APIConstants.API_CODE,
      data: formData,
    }).catch((error) => {
      SetLoading(false);
      return error?.response;
    });

    if (
      response?.data === "Email already confirmed" ||
      response?.data === "Invalid token"
    ) {
      SetLoading(false);
      return response;
    }

    if (!resend) {
      ProcessLogin(response);
    }

    return response;
  }

  // async function ResendConfirmationEmail(username) {
  //   SetLoading(true);
  //   let formData = new FormData();
  //   formData.append("Username", username);
  //   formData.append("BaseUrl", window.location.origin);
  //   formData.append("Resend", true);

  //   let result = await axios({
  //     method: "post",
  //     url: APIConstants.API_URL + "ConfirmEmail?code=" + APIConstants.API_CODE,
  //     data: formData,
  //   }).catch((error) => {
  //     SetLoading(false);
  //     return error?.response;
  //   });

  //   SetLoading(false);
  //   return result;
  // }

  async function ChangeUserPassword(username, oldPassword, newPassword) {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", oldPassword);
    formData.append("newPassword", newPassword);

    const result = await axios({
      method: "post",
      url:
        APIConstants.API_URL + "ChangePassword?code=" + APIConstants.API_CODE,
      data: formData,
    }).catch((error) => {
      throw error;
    });

    return result;
  }

  function Logout() {
    localStorage.setItem("loggingOut", "true");
    cookies.remove("sessionKey", {
      domain: "." + window.location.hostname.split(".").slice(-2).join("."),
      path: "/",
    });
    cookies.remove("sessionKey", {
      path: "/",
    });
    cookies.remove("fmsst", {
      domain: "." + window.location.hostname.split(".").slice(-2).join("."),
      path: "/",
    });
    cookies.remove("fmsst", {
      path: "/",
    });
    SetUser("none");
    SetSessionCookie(null);
  }

  async function GetUsers({
    username = null,
    amount = 1,
    minimal = null,
    includeProfile = false,
    lastXMonths = null,
  }) {
    const users = await axios({
      method: "get",
      url: APIConstants.API_URL + "Get?code=" + APIConstants.API_CODE,
      params: {
        username: username,
        amount: amount,
        minimal: minimal,
        includeProfile: includeProfile,
        lastXMonths: lastXMonths ? 6 : null,
      },
      headers: {
        "Content-Type": "application/json",
        GetType: "User",
        sessionKey: user?.session?.sessionKey ?? "",
      },
    });
    return users;
  }

  async function UpdateUserProfile(formData) {
    const userProfileApiNames = [
      "avatar",
      "bio",
      "autoplayDisabled",
      "authorInfo",
      "likedPublic",
    ];
    let shouldUpdateUser = false;
    let shouldUpdateUserProfile = false;
    const userFormData = new FormData();
    const userProfileFormData = new FormData();

    for (const key of formData.keys()) {
      if (key === "id" || key === "avatarPreview") {
        continue;
      }

      if (userProfileApiNames.includes(key)) {
        shouldUpdateUserProfile = true;
        userProfileFormData.append(key, formData.get(key));
      } else {
        shouldUpdateUser = true;
        userFormData.append(key, formData.get(key));
      }
    }

    let response;

    // If user profile form data is not empty, update user profile
    if (shouldUpdateUserProfile) {
      userProfileFormData.append("id", formData.get("id"));
      response = await axios
        .post(
          APIConstants.API_URL + "Update?code=" + APIConstants.API_CODE,
          userProfileFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              UpdateType: "UserProfile",
              SessionKey: user?.session?.sessionKey,
            },
          }
        )
        .catch((error) => {
          return error;
        });
    }

    // If user form data is not empty, update user

    if (shouldUpdateUser) {
      userFormData.append("id", formData.get("id"));
      response = await axios
        .post(
          APIConstants.API_URL + "Update?code=" + APIConstants.API_CODE,
          userFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              UpdateType: "User",
              SessionKey: user?.session?.sessionKey,
            },
          }
        )
        .catch((error) => {
          return error;
        });
    }

    return response;
  }

  async function RefreshUser() {
    LoginBySession();
  }

  function UseTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
      const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
        if (prev.current[k] !== v) {
          ps[k] = [prev.current[k], v];
        }
        return ps;
      }, {});
      if (Object.keys(changedProps).length > 0) {
        console.log("Changed props:", changedProps);
      }
      prev.current = props;
    });
  }

  async function DeleteUserAndProfile() {
    const formData = new FormData();
    formData.append("id", user.id);

    const response = await axios
      .post(
        APIConstants.API_URL + "Delete?code=" + APIConstants.API_CODE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            DeleteType: "User",
            SessionKey: user.session.sessionKey,
          },
        }
      )
      .catch((error) => {
        return error;
      });
    return response;
  }

  function ProcessScrolledPage(atTop = false) {
    if (atTop && pullToRefreshEnabled) {
      SetPullToRefreshEnabled(false);
      // setTimeout(() => {
      //   SetPullToRefreshEnabled(true);
      // }, 1000);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("loggingOut")) {
      localStorage.removeItem("loggingOut");
      SetUser("none");
      SetSessionCookie(null);
      SetLoading(false);
      return;
    }
    if (sessionCookie) {
      LoginBySession();
    } else {
      SetUser("none");
      SetLoading(false);
      SetLoggingIn(false);
    }

    GenerateAnonSession();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CurrentContext.Provider
      value={{
        anonSession,
        user,
        loading,
        loggingIn,
        returnTo,
        pullToRefreshEnabled,
        Login,
        Logout,
        Register,
        SetReturnToPath,
        ConfirmEmail,
        ChangeUserPassword,
        GetUsers,
        UpdateUserProfile,
        RefreshUser,
        DeleteUserAndProfile,
        UseTraceUpdate,
        ProcessScrolledPage,
      }}
    >
      {children}
    </CurrentContext.Provider>
  );
}

export function UseUserContext() {
  const context = useContext(CurrentContext);
  if (!context) {
    throw new Error("useUserContext must be used within a Context");
  }
  return context;
}

export default UserContext;
